<template>
    <section>

        <b-row>
            <b-col md="3" lg="3">
                <b-card class="text-center">
                    <div class="truncate">
                        <b-form-select v-model="selected" :options="options" @change="modelSelected"></b-form-select>
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">
                <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit">
                </stat>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <bruna-element-loader :dataUrl="predictionsChart">
                </bruna-element-loader>
            </b-col>
        </b-row>

            
        <b-row>
            <b-col sm="12">
                <ag-grid-vue
            :helper="true"
              style="width: 100%; height: 600px"
              class="ag-theme-alpine"
              :columnDefs="historicoComparePredictionsTableColumnDefs"
              :rowData="historicoComparePredictionsTableRowData"
            ></ag-grid-vue>
            </b-col>
        </b-row>
        
    </section>
</template>
        
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import Stat from '@/views/clients/base/components/Stat.vue'

import useApiServices from '@/services/useApiServices.js';

import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import { AgGridVue } from "ag-grid-vue";


import axios from "@axios";
var qs = require('qs');

import
{
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    BOverlay,
    BSpinner


} from "bootstrap-vue";



export default {
    components: {
        AgGridTable,
        BRow,
        BCol,
        BButtonToolbar,
        BButtonGroup,
        BButton,
        BInputGroup,
        BFormInput,
        BFormGroup,
        BFormTextarea,
        BFormSelect,

        BCard,
        BCardTitle,
        BCardSubTitle,
        BCardBody,


        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,

        Stat,

        BOverlay,
        BSpinner,

        BrunaElementLoader,
        AgGridVue





    },

    props: {


        models: {
            type: Array,
            required: true,
        },


    },


    methods: {

        debugChange(value)
        {

            console.log(value)

            let decoded = JSON.parse(value)





        },

 
        modelSelected(selected)
        {

            this.callStats()

            let query = qs.stringify({ model: this.selected })

            this.predictionsChart = useApiServices.comparePredictions2.predictionsChart + '?' + query

            this.historicoComparePredictionsTable = useApiServices.comparePredictions2.historicoComparePredictionsTable + '?' + query



            axios.request({
        method: "get",
        url: this.historicoComparePredictionsTable,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        {
  
          this.historicoComparePredictionsTableColumnDefs = response.data.columnDefs;
          this.historicoComparePredictionsTableRowData = response.data.rowData;
  
        })
          


        },




        onGridReady(gridApi, gridColumnApi, params)
        {

            this.gridApi = gridApi

            console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })



        },

        onGetRowsLoaded(gridApi, gridColumnApi, params)
        {
            console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })



        },


        callStats()
        {

            let query = qs.stringify({ model: this.selected })


            axios.request({
                method: "get",
                url: useApiServices.comparePredictions2.stats + '?' + query ,
                headers: {
                    Accept: "application/json",
                },
            })
                .then((response) =>
                {
                    this.stats = response.data
                })




 
        }



    },

    mounted()
    {
        console.log("mounted", this.selected)

        this.callStats()



        axios.request({
        method: "get",
        url: this.historicoComparePredictionsTable,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        {
  
          this.historicoComparePredictionsTableColumnDefs = response.data.columnDefs;
          this.historicoComparePredictionsTableRowData = response.data.rowData;
  
        })
          

    },




    created()
    {


    },


    data()
    {

        let options = []
        let selected = null;

        this.models.forEach(model =>
        {
            let name = model.name

            if (model.default)
            {
                selected = model
                name = name + ' (Default)'
            }

            options.push({ value: model, text: name })


        });

        console.log({ options })


        let query = qs.stringify({ model: selected })


        return {

            options: options,
            selected: selected,


            showOverlay: false,
            overlayMessage: "Cargando...",



            gridApi: null,



            historicoComparePredictionsTable: useApiServices.comparePredictions2.historicoComparePredictionsTable + '?' + query,
            predictionsChart: useApiServices.comparePredictions2.predictionsChart + '?' + query,


            historicoComparePredictionsTableColumnDefs: [],
            historicoComparePredictionsTableRowData: [],

            stats: [],






        };
    },
};
</script>
<style lang="scss" scoped>
span {
    font-size: 14px;
}
</style>
      
      
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
    background-color: #00bcd4 !important;
}
</style>
      
<style lang="scss" scoped>
.card {
    .card-title {
        margin-bottom: 1.5rem !important;
    }

    ::v-deep .card-header {
        .heading-elements {
            position: static;
            cursor: inherit;

            .list-inline {
                display: block;

                li {
                    a {
                        padding: 0;
                    }

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
</style>
        