<template>
  <section>
    <b-tabs>

      <b-tab v-for="(meta_model, index) in meta_models" :key="index" :title="meta_model.name">
        <model-tab :models="meta_model.models"></model-tab>
      </b-tab>




    </b-tabs>
  </section>
</template>
        
<script>


import CV12Tab from "./tabs/CV12Tab.vue"
import CV14Tab from "./tabs/CV14Tab.vue"
import ModelTab from "./tabs/ModelTab.vue"

import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {

    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,


    BOverlay,
    BSpinner,

    CV12Tab,
    CV14Tab,
    ModelTab



  },



  mounted()
  {


  },




  created()
  {

  },


  data()
  {

    return {
      meta_models: [

        {
          name: 'FeT CV12',
          models: [
            {
              id: 'fet_cv12_v1',
              name: 'FeT CV12 V1',
              default: false,
              variable_name: 'CV12_FeT'
            },
            {
              id: 'fet_cv12_v2',
              name: 'FeT CV12 V2',
              default: true,
              variable_name: 'CV12_FeT'
            },
            {
              id: 'fet_cv12_v3',
              name: 'FeT CV12 V3 Mina',
              default: false,
              variable_name: 'CV12_FeT'
            }
          ]
        },

        {
          name: 'FeDtt CV12',
          models: [
            {
              id: 'fedtt_cv12_v1',
              name: 'FeDtt CV12 V1',
              default: false,
              variable_name: 'CV12_Fedtt'
            },
            {
              id: 'fedtt_cv12_v2',
              name: 'FeDtt CV12 V2',
              default: true,
              variable_name: 'CV12_Fedtt'
            },
            {
              id: 'fedtt_cv12_v3',
              name: 'FeDtt CV12 V3 Mina',
              default: false,
              variable_name: 'CV12_Fedtt'
            }
          ]
        },

        {
          name: 'FeMag CV12',
          models: [
            {
              id: 'femag_cv12_v1',
              name: 'FeMag CV12 V1',
              default: false,
              variable_name: 'CV12_FeMag'
            },
            {
              id: 'femag_cv12_v2',
              name: 'FeMag CV12 V2',
              default: false,
              variable_name: 'CV12_FeMag'
            },
            {
              id: 'femag_cv12_v3',
              name: 'FeMag CV12 V3 Mina',
              default: true,
              variable_name: 'CV12_FeMag'
            }
          ]
        },

        {
          name: 'FeT CV14',
          models: [
            {
              id: 'fet_cv14_v1',
              name: 'FeT CV14 V1',
              default: false,
              variable_name: 'CV14_FeT'
            },
            {
              id: 'fet_cv14_v2',
              name: 'FeT CV14 V2',
              default: true,
              variable_name: 'CV14_FeT'
            },
            {
              id: 'fet_cv14_v3',
              name: 'FeT CV14 V3 Mina',
              default: false,
              variable_name: 'CV14_FeT'
            }
          ]
        },

        {
          name: 'FeDtt CV14',
          models: [
            {
              id: 'fedtt_cv14_v1',
              name: 'FeDtt CV14 V1',
              default: true,
              variable_name: 'CV14_Fedtt'
            },
            {
              id: 'fedtt_cv14_v2',
              name: 'FeDtt CV14 V2',
              default: false,
              variable_name: 'CV14_Fedtt'
            },
            {
              id: 'fedtt_cv14_v3',
              name: 'FeDtt CV14 V3 Mina',
              default: false,
              variable_name: 'CV14_Fedtt'
            }
          ]
        },

        {
          name: 'FeMag CV14',
          models: [
            {
              id: 'femag_cv14_v1',
              name: 'FeMag CV14 V1',
              default: false,
              variable_name: 'CV14_FeMag'
            },
            {
              id: 'femag_cv14_v2',
              name: 'FeMag CV14 V2',
              default: true,
              variable_name: 'CV14_FeMag'
            },
            {
              id: 'femag_cv14_v3',
              name: 'FeMag CV14 V3 Mina',
              default: false,
              variable_name: 'CV14_FeMag'
            }
          ]
        },



      ]




    };
  },

};

</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
      
      
      
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
      
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        